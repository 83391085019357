<!--
 * @Author: your name
 * @Date: 2021-05-12 11:44:50
 * @LastEditTime: 2021-05-16 14:11:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one2\src\views\addskill\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation msg="新增技能"></navigation>
    <div class="theclass">
      <div class="jincl">推荐技能</div>
      <div class="jnlist">
        <div
          @click="insertsjill(item)"
          v-for="(item, index) in tjarray"
          v-bind:key="index"
          class="jndiv"
        >
          {{ item.position_name }}
        </div>
      </div>
      <div class="jincl">新增自定义技能</div>
      <div @click="toskilltwo" class="addzdc">
        <div>请输入自定义技能名称</div>
        <van-icon size=".35rem" color="#A1A1A1" name="plus" />
      </div>
      <div class="jincl">选择技能类型</div>
      <div
        v-for="(item, index) in skilllist"
        v-bind:key="index"
        @click="toadd3(item)"
        class="mrskillc toc"
      >
        <div>{{ item.position_name }}</div>
        <van-icon size=".3rem" name="arrow" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tjarray: [],
      userid: "",
      skilllist: [], //技能列表
    };
  },
  created() {
    this.gettuijian();
    this.getskilllist();
  },
  methods: {
    //获取技能列表
    getskilllist() {
      this.$http
        .post("/firm/v1/Skillme/positionList", {
          reqType: "userinfo",
          pid: "",
          name: "",
        })
        .then((res) => {
          this.skilllist = JSON.parse(res.data).data;
        });
    },
    toadd3(item) {
      this.$router.push({
        path: "/addskill3",
        query: {
          phone: this.$route.query.phone,
          positionId: btoa(item.positionId),
          position_name: item.position_name,
        },
      });
    },
    toskilltwo() {
      this.$router.push({
        path: "/addskill2",
        query: {
          phone: this.$route.query.phone,
        },
      });
    },
    insertsjill(item) {
      this.$http
        .post("/firm/v1/Skillme/userSkill", {
          reqType: "userinfo",
          phone: atob(this.$route.query.phone),
          skill_type: item.type,
          skill_name: item.position_name,
          skill_id: item.id,
          mim_id: localStorage.getItem("userId"),
          status: "1",
        })
        .then((res) => {
          this.$toast("添加成功");
          this.$router.go(-1);
        });
    },
    gettuijian() {
      this.$http
        .post("/firm/v1/Skillme/getHot", {
          reqType: "userinfo",
          phone: atob(this.$route.query.phone),
        })
        .then((res) => {
          this.tjarray = JSON.parse(res.data).data.hot;
        });
    },
  },
};
</script>
<style scoped>
.toc {
  margin-top: 0.25rem;
}
.mrskillc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.32rem;
  line-height: 0.48rem;
  color: #333333;
  padding: 0.36rem 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #bfbfbf;
}
.addzdc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem;
  line-height: 0.48rem;
  color: #686868;
  padding: 0.24rem 0;
  margin-top: 0.08rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #bfbfbf;
  margin-bottom: 1.1rem;
}
.jnlist {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.4rem;
  margin-bottom: 1.1rem;
}
.jndiv {
  padding: 0.15rem 0.49rem;
  background-color: #ffede4;
  font-size: 0.24rem;
  line-height: 0.3rem;
  color: #f44c06;
  margin-right: 0.13rem;
  margin-bottom: 0.25rem;
  border-radius: 0.28rem;
}
.jincl {
  font-size: 0.42rem;
  line-height: 0.48rem;
  color: #333333;
  font-weight: bold;
}
.theclass {
  margin: 0.3rem 0.48rem;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
</style>
